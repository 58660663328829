import React, { useContext, useEffect, useState } from "react";
import { FirebaseAuthContext } from "../../context/firebase-auth";
import { getRequestConfigWithUserToken, toastError, toastSuccess } from "../../util/util";
import axios from "axios";
import { navigate } from "gatsby";

const TourOperatorSignUp = () => {
    const [userSignUpHandled, setUserSignUpHandled] = useState(false);
    const user = useContext(FirebaseAuthContext);

    const handleTourOperatorSignUp = async () => {
        // Create User in Database
        const [config, configError] = await getRequestConfigWithUserToken(user);

        if (configError !== null) {
            toastError("Sorry we couldn't get the user details, please contact our team.");
            return;
        }

        if (!process.env.GATSBY_BACKEND_URL) return;
        try {
            const response = await axios.post(
                `${process.env.GATSBY_BACKEND_URL}/user/create-operator-with-new-organization`,
                {},
                config
            )
            if (response.status === 200) {
                toastSuccess("Thanks for registering, welcome to CloudSafari!");
            } else {
                toastError("Sorry, something went wrong when registering your account. Please contact our team.");
            }
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        if (!userSignUpHandled && user) {
            handleTourOperatorSignUp();
            setUserSignUpHandled(true);
            navigate('/operator');
        }
    }, [user, userSignUpHandled]);
    
    return (
        <div className="tw-flex tw-justify-center tw-items-center">
                <span className="visually-hidden">Creating your account ...</span>
        </div>
    )
}

export default TourOperatorSignUp;